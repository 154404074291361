
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import RollSlider from './RollSlider'

import Divider from '@material-ui/core/Divider';


class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    let today = new Date();

    let filteredPosts = posts.filter(function(post){
      let postDate = new Date(post.node.frontmatter.date)
      return postDate <= today;
    })
  
    let threePosts = filteredPosts;

    if(threePosts.length > 3) {
        threePosts.length = 3;
    }

    if(posts.length > 0){
      return (
        <div className="slider-wrapper">
          <h1>SENASTE NYHETERNA</h1>
          <Divider/>
          <RollSlider array={threePosts}/>
        </div>
      )
    } else {
      return(
        <div> Woops! Inga nyheter :( </div>
      )
    }
    
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
