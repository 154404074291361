
import React from 'react'
import {graphql, StaticQuery } from 'gatsby'

import RollSlider from './RollSlider'

import Divider from '@material-ui/core/Divider';


class EventRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    let today = new Date();
    let futureEvents = events.filter(function(event){
        let eventDate = new Date(event.node.frontmatter.date)
        return eventDate > today
    })

    if(futureEvents.length > 3) {
        futureEvents.length = 3;
    }

    if(events.length > 0){
      return (
        <div className="slider-wrapper">
          <h1>KOMMANDE EVENT</h1>
          <Divider/>
          <RollSlider array={futureEvents}/>
        </div>
      )
    } else {
      return(
        <div> Woops! Inga event :( </div>
      )
    }
    
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query EventRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "event-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EventRoll data={data} count={count} />}
  />
)
