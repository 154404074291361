import React from 'react';
import Slider from "react-slick";
import { Link } from 'gatsby'

export default function RollSlider({array}){
   
   const sliderSettings = {
      infinite: true,
      arrows: false,
      speed: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: 'slick-dots news-dots',
      adaptiveHeight: true
    };

    return(

        <Slider {...sliderSettings} className="news-slider">
          {array.map(({ node: item}) => (
            <Link to={item.fields.slug}  key={item.id}>
            <div className="news-item">
              
              <div className="post-header">
                <p className="title">
                  {item.frontmatter.title}
                </p>
                <span className="date">
                  {item.frontmatter.date}
                </span>
              </div>
              <p>
                {item.excerpt}
              </p>
              <button className="btn-readmore">Läs mer</button>
              
            </div>
            </Link>
            ))}

          </Slider>
        
    )
}