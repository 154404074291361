import React from 'react'
import heroStyles from './mobilehero.module.css'

export default function DesktopHero({heading, subheading}){
    return(
        <div className={heroStyles.desktopHero}>
            <div className={heroStyles.background}>
                <div className={heroStyles.wrapper}>
                    <div className={heroStyles.content}>
                        <h1>{heading}</h1>
                        <h3>{subheading}</h3>
                    </div>
                </div>  
            </div> 
        </div>
    )
}