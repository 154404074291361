import React from 'react'
import heroStyles from './mobilehero.module.css'
import Scroll from '../../img/scroll.svg'

export default function MobileHero({heading, subheading, quote}){
    return(
        <div className={heroStyles.mobileHero}>
            <div className={heroStyles.header}>
                <h1>{heading}</h1>
                <h2>{subheading}</h2>
            </div>
            <div className={heroStyles.text}>
                <p>{quote}</p>
            </div>
            <div className={heroStyles.keepScrolling}>
                <img className="animated infinite pulse" src={Scroll} alt="Fortsätt scrolla!"/>
            </div>
        </div>
    )
}