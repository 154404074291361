import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Hidden, Container, Grid } from '@material-ui/core';

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import EventRoll from '../components/EventRoll'
import MobileHero from '../components/StartPage/MobileHero'
import DesktopHero from '../components/StartPage/DesktopHero'
import CourseList from '../components/Courses/CourseList'
import NewCourses from '../components/NewCoursesList'

import Divider from '@material-ui/core/Divider';
import Treatment from '../components/Treatment/Treatment';


export const IndexPageTemplate = ({
  image,
  imageText,
  quote,
  title,
  heading,
  subheading,
  description,
  intro,
}) => (
  <div className="start-page">
    <Hidden mdUp>
      <MobileHero quote={quote} heading={heading} subheading={subheading}/>
    </Hidden>
    <Hidden smDown>
      <DesktopHero quote={quote} heading={heading} subheading={subheading}/>
    </Hidden>
    <Container>
      <div className="wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <div className="main-content">
              <Hidden smUp>
                <div className="mobile-leading">
                    <h1>
                      {intro.heading}
                    </h1>
                    <Divider/>
                    <p>
                      {intro.description}
                    </p>
                </div>
              </Hidden>
              <CourseList/>
              <Treatment/>
              <div
                className="full-width-image margin-top-0"
                style={{
                  backgroundImage: `url(${
                    !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                  })`,
                  backgroundPosition: `center`,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    lineHeight: '1',
                    justifyContent: 'space-around',
                    alignItems: 'left',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >
                  <h3
                    className=""
                    style={{
                      backgroundColor: '#F277C6',
                      color: 'white',
                      lineHeight: '1',
                      padding: '20px',
                    }}
                  >
                    {imageText}
                  </h3>
                </div>
              </div>
              <Hidden mdUp>
                <div className="d-lg-none mt-15">
                <NewCourses/>
                </div>
                <div className="news d-block d-lg-none">
                  <BlogRoll />
                  <EventRoll/>
                </div>
              </Hidden>
            </div>
          </Grid>
          <Grid item md={4}>
            <Hidden smDown>
              <div className="side-content d-none d-lg-block col-lg-4">
                <BlogRoll/>
                <EventRoll/>
                <NewCourses/>
                <div className="spotlight">
                  <div>
                      
                  </div>
                </div>
              </div>
            </Hidden>

          </Grid>

        </Grid>
      </div>
    </Container>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        imageText={frontmatter.imageText}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        quote={frontmatter.quote}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageText
        heading
        subheading
        quote
        description
        intro {
          heading
          description
        }
      }
    }
  }
`
