
import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery, Link } from 'gatsby'
import Hidden from '@material-ui/core/Hidden'

class Growth extends React.Component {
  render() {

    function renderColor(color){
        switch(color){
            case 'Rosa':
                return 'pink'
            case 'Lila':
                return 'purple'
            case 'Turkos':
                return 'turqoise'
            case 'Gul':
                return 'yellow'
            default:
                return ''
        }
    }
    const { data } = this.props
    const { edges: treatment } = data.allMarkdownRemark
    console.log(treatment)
    return (
        <div className="courses-list">
            {treatment && treatment.map(({node: treatment}) => (
            <Link to={treatment.fields.slug}  key={treatment.id}>
                <div className={`course ${renderColor(treatment.frontmatter.color)}`}>
                    {treatment && treatment.frontmatter.image && (

                    <div className="image">
                        <div className="bg-wrapper" style={{backgroundImage: `url(${!!treatment.frontmatter.image.childImageSharp ? treatment.frontmatter.image.childImageSharp.fluid.src : treatment.frontmatter.image})`}}>
                        </div>
                    </div>
                    )}
                    <div className="text">
                        <h1>{treatment.frontmatter.title}</h1>
                        <Hidden smDown>
                          <p>{treatment.frontmatter.description}</p>
                        </Hidden>
                        
                    </div>
                </div>
            </Link>
         ))}
        </div>
    )} 
}

Growth.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
    <StaticQuery
      query={graphql`
        query Growth {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "growth-page" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  color
                  image{
                    childImageSharp{
                        fluid(maxWidth: 500, quality: 64){
                            ...GatsbyImageSharpFluid
                        }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Growth data={data}/>}
    />
  )
