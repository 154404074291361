import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

class NewCourseList extends React.Component{

  filterThreeLatest = (courses) => {
    let allCourses = [];
    for(let course of courses){
      if(course.node.frontmatter.coursesList) {
        let link = course.node.fields.slug
        for(let i of course.node.frontmatter.coursesList){
          i = {...i, link}
          allCourses = [...allCourses, i]
        }
      }
    }
    allCourses.sort(function(a,b){
      let c = new Date(a.date);
      let d = new Date(b.date);
      return d-c;
    })
    if(allCourses.length > 5){
      allCourses.length = 5
    }
    return allCourses;
  }

  render(){
    const { data } = this.props
    let courses = data.allMarkdownRemark.edges
    let threeCourses = this.filterThreeLatest(courses)

    return(
      <div className="new-courses">
          <h2>Nya kurser</h2>
          <div className="new-courses-list">
            <ul>
              {threeCourses && threeCourses.map((course, i) => {
                let k = course.date.split('T')
                let date = new Date(k[0])
                return(
                  <li key={i}><a href={course.link}>{course.courseName} | {date.getDate()}/{date.getMonth() + 1}</a></li>
                )
              })}
            </ul>
          </div>
      </div>
    )
  }
}


export default (props) => (
  <StaticQuery
    query={graphql`
      query NewCourseListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "course" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                coursesList{
                  about,
                  date,
                  time,
                  cost,
                  courseName,
                  location,
                  instructor,
                  deadline,
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <NewCourseList data={data} props={props} />}
  />
)
